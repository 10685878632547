import consumer_trains_order_getTrainsReturnOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsReturnOrderDetail'
import consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2'
import consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-refund_getTrainsReturnOrderDetailCenter'
import consumer_trains_order_gtSchedule from '@/lib/data-service/haolv-default/consumer_trains_order_getSchedule'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import moment from 'moment'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data () {
        return {
            checkList: [],
            refundNo: '',
            orderInfo:{
                receiveOption:[
                  {
                    headCounts:null,
                    receiveFee:null,
                    refundCharge:null,
                    robTciketFee:null,
                    serviceFee:null,
                  },
                ],
                trainInfo:{
                    trainNo:null
                }
            },
            routeName: '',
            // orderInfo: {
            //     refundNo:'2656265265265',
            //     orderNo:'18185721357',
            //     gmtCreate:'2020-07-07  08:40:50',
            //     paymentChanelText:'授信支付',
            //     scheduledWayText:'出差创建',
            //     refundStatusText:'退票成功',
            //     receiveAmount:'400',
            //     refundAmount:'200',
            //     receiveOption:{
            //         serviceFee:'4',
            //         refundCharge:'96',
            //     },
            //     evectionNo:'CA58587458',
            //     buyerContactName:'郭鑫',
            //     buyerContactPhone:'18788888888',
            //     trainInfo:{
            //         trainNo:'G696',
            //         startDate:'2021-09-10',
            //         whatDay:'星期五',
            //         seatName:'软卧',
            //         fromStationName:'广州南站',
            //         startTime:'06:25',
            //         spanTime:'6小时48分钟',
            //         toStationName:'上海虹桥',
            //         arriveTime:'09:15',
            //         seatPrice:'50',
            //     },
            //     passengers:[
            //         {
            //             passengerCardTypeText: "身份证",
            //             passengerName: "李明丽",
            //             passengerTypeText: "成人",
            //             unSensitivePassengerCardNumber: "522425********0147",
            //             unSensitivePassengerPhone: "181****1357"
            //         },
            //         {
            //             passengerCardTypeText: "护照",
            //             passengerName: "张三",
            //             passengerTypeText: "成人",
            //             unSensitivePassengerCardNumber: "E*****000",
            //             unSensitivePassengerPhone: "181****1357"
            //         }
            //     ]
            // },
            scheduleList: [],
        }
    },
    components: {},
    created () {

    },
    mounted () {
        this.routeName = this.$route.name;
    },
    async activated () {
        this.refundNo = this.$route.query.orderNo;
        if (this.$route.name == 'admin-order-center-train-refund-details') {
            await this.getRefundOrderDetail();
        } else {
            await this.getRefundOrderDetailV2();
        }
        await this.getSchedule();
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {

        filterApproveStatus(val) {
            // 0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '未开始'
            } else if (val === 1) {
                return '待我审'
            } else if (val === 2) {
                return '审核通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
    },
    methods: {
        clickInsurance(val){
            if (val.insuranceOrderId) {
                let data = {
                    insuranceOrderId: val.insuranceOrderId,
                };
                consumer_insurance_getEInsuranceOrderUrl(data).then(res => {
                    const __this = this;
                    const eleLink = document.createElement('a');
                    eleLink.href = res.datas.epolicyUrl;
                    eleLink.target = '_blank';
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                })
            } else {
                this.$message.error('正在生成保单信息，请稍后查询...');
            }

        },
        clickSpan(){
            if (this.$route.meta.data_for_back_layout && this.$route.meta.data_for_back_layout.layoutType === 'orderCenterType') {
                this.$router.push({
                    name:'admin-order-center-train-refund-list'
                })
            } else {
                this.$router.push({
                    name:'admin-my-order-train-refund-list'
                })
            }
        },
        async getRefundOrderDetail() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let [err, res] = await awaitWrap(consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter({refundNo: this.refundNo}));

            if (err) {
                loading.close()
                return
            }
            loading.close()
            this.orderInfo = res.datas
            this.orderInfo.receiveOption = [res.datas.receiveOption];
        },
        async getRefundOrderDetailV2() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });


            let [err, res] = await awaitWrap(consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2({refundNo: this.refundNo}))

            if (err) {
                loading.close()
                return
            }
            loading.close()
            this.orderInfo = res.datas
            this.orderInfo.receiveOption = [res.datas.receiveOption];
        },
        async getSchedule() {
            if (!this.orderInfo.applyNo) {
                return
            }
            let res = await consumer_trains_order_getSchedule({applyNo: this.orderInfo.applyNo})
            this.scheduleList = res.datas
        },
        checkEvection() {
            this.$router.push({
                name: 'admin-my-apply-manage-apply-info',
                query: {
                    applyId: this.orderInfo.applyId,
                    type: 'info',
                    approveType: 1,
                    lastMenu: 'adminMyApply'
                }
            })
        },
        back() {
            this.$router.back();
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
