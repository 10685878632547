// consumer_t-od-trains-order-refund_getTrainsReturnOrderDetailCenter
const __request = require(`./__request/__request_contentType_json`)
const consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/t-od-trains-order-refund/getTrainsReturnOrderDetailCenter',
        data: data
    }
    return __request(pParameter)
}
export default consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailCenter