// consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2 New获取火车票退款订单详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/yinzhilv-tmc/API/getTrainsReturnOrderDetailV2UsingPOST
const consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2 = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-trains-order-refund/getTrainsReturnOrderDetailV2',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2;