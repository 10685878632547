// consumer_trains_order_getTrainsReturnOrderDetail 获取火车票退款订单详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.13:8765/doc.html#/haolv-consumer/trains-order-controller/getTrainsReturnOrderDetailUsingPOST
const consumer_trains_order_getTrainsReturnOrderDetail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/getTrainsReturnOrderDetail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_getTrainsReturnOrderDetail;